import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import meplants from '../assets/images/MePlants.jpg'
import mebook from '../assets/images/mebook.jpg'
import zelda from '../assets/images/zelda.jpg'
const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>About Me</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>About Me</h1>
                    </header>
                    <div className="box alt">
                        <div className="grid-wrapper">
                            <div className="col-4"><span className="image fit"><img src={meplants} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={zelda} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={mebook} alt="" /></span></div>
                        </div>
                    </div>
                    <p>I am a student currently in my second year of graduate school pursuing a PhD in Applied Mathematics at Stony Brook University. I played the violin for a few years and have a number of nerdy interests outside of math. I love my cat, Zelda (pictured above), and making food with herbs from my herb garden. </p>
                    <p>I also love math and I enjoy showing others the beauty of the subject. I have put a lot of time and effort into becoming someone who could teach math to any person. The full list of my (relevant) positions is available on <a href="/credentials">my credentials page</a>, but in short, I gravitated towards jobs related to helping students learn.</p>
                    <h3>Subjects:</h3>
                    <p>Algebra (I, II, & III), Geometry, Trigonometry, Precalculus, Calculus (all levels), Multivariable Calculus, Discrete Math, Probability & Statistics, Combinatorics, Graph Theory, Game Theory, Number Theory, Real Analysis (I), Abstract Algebra (I), and more... </p>
                    <ul className="actions horizontal">
                        <li><a href="/services" className="button fit">View Options & Pricing</a></li>
                        <li><a href="/contact" className="button fit">Schedule a Session</a></li>
                    </ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic
